.vermieter-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		background-image: url(RESOURCE/img/vermieter_banner_new.webp);
	}

	.img {
		width: 100%;
		height: auto;
		object-fit: cover;
	}

	strong {
		font-weight: 600;
	}

	#lastviewed-units {
		display: none;
	}
}