.static-view {
	margin-top: var(--page-margin-top);

	.inner-banner {
		/*	background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		height: 50vh;*/

		&.contact {
			background-image: url(RESOURCE/img/contact_banner.webp);
		}

		&.anfahrt {
			background-image: url(RESOURCE/img/anfahrt_banner.webp);
		}

		&.abreise {
			background-image: url(RESOURCE/img/anreise.jpg);

		}

		&.gasteservice {
			background-image: url(RESOURCE/img/gasteservice_banner.webp);
		}

		&.regional {
			background-image: url(RESOURCE/img/regionales.jpg);
			background-position: center;
		}

		&.standort {
			background-image: url(RESOURCE/img/inselorte.jpg);
			background-position: center;

		}

	}

	&.regionale-v1 {
		a {
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}
}