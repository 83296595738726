.regionen-v1 {
	margin-top: var(--page-margin-top);
	padding-top: var(--page-padding-top);

	.regionen-bl {
		position: relative;
		display: block;
		margin-bottom: 30px;

		.regionen-title {
			padding: 10px;
			color: #fff;
			text-align: center;
			background-color: var(--overlay-color);
			position: absolute;
			bottom: 0;
			width: 100%;
			margin: 0;
		}

		.noimage {
			background-image: url(RESOURCE/img/no-img.png);
			width: 100%;
			height: 320px;
			background-size: cover;
			background-position: center;
			display: block;

			&.region-12005 {
				background-image: url(RESOURCE/img/12005_img.jpg);
			}

			&.region-12003 {
				background-image: url(RESOURCE/img/12003_img.jpg);
			}

			/*	&.region-12161 {
				background-image: url(RESOURCE/img/12161_img.jpg);
			}*/

			&.region-12006 {
				background-image: url(RESOURCE/img/12006_img.jpg);
			}

			/*	&.region-12000 {
				background-image: url(RESOURCE/img/12000_img.jpg);
			}*/

			&.region-11996 {
				background-image: url(RESOURCE/img/11996_img.jpg);
			}

			&.region-12001 {
				background-image: url(RESOURCE/img/12001_img.jpg);
			}

			&.region-11994 {
				background-image: url(RESOURCE/img/11994_img.jpg);
			}

			&.region-12002 {
				background-image: url(RESOURCE/img/12002_img.jpg);
			}

			&.region-11998 {
				background-image: url(RESOURCE/img/11998_img.jpg);
			}

			&.region-12004 {
				background-image: url(RESOURCE/img/12004_img.jpg);
			}

			&.region-11995 {
				background-image: url(RESOURCE/img/11995_img.jpg);
			}


		}
	}

	.img-thumbnail {
		width: 100%;
		border: 0;
		padding: 0;
		border-radius: 0;
		max-height: 450px;
		object-fit: cover;
	}
}