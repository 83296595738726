.themes-v2 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-auto-rows: minmax(160px, auto);
	grid-gap: 1.5em;
	grid-template-areas:
		'img1 img1 img2 img2'
		'img1 img1 img2 img2'
		'img3 img3 img4 img4'
		'img3 img3 img4 img4';


	.theme-pic {
		object-fit: cover;
		height: 100%;
		border-radius: var(--border-radius);
	}

	@media (max-width: 767px) {
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none
	}

	.img1 {
		grid-area: img1;
	}


	.img2 {
		grid-area: img2;
	}

	.img3 {
		grid-area: img3;
	}

	.img4 {
		grid-area: img4;
	}



	[class^='theme-card'] {
		color: white;
		text-decoration: none;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
		padding: 10px;

		@media (max-width: 767px) {
			height: 35vh;
		}


		&:hover {
			text-decoration: none;
		}

		h2 {
			font-size: var(--theme-card-title);
			color: var(--theme-card-text-color);
		}

		p {
			font-size: var(--theme-card-description);
			color: var(--theme-card-text-color);
		}

		a {
			text-decoration: none;
		}
	}


	.theme-card-3 {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		padding: 0;
		border-radius: var(--border-radius);
		position: relative;
	}

	.theme-card-3--description-container {
		background-color: var(--overlay-color);
		padding: 10px;
		transition: 1s;
		border-bottom-left-radius: var(--border-radius);
		border-bottom-right-radius: var(--border-radius);
		position: absolute;
		width: 100%;

	}



	@media (min-width: 767px) {


		.theme-card-3--description-container {
			height: 50px;
		}

		.theme-card-3--description-container .title {
			text-align: center;
			padding: 0px 10px;
			font-size: 20px;
			font-weight: 400;
		}

		.theme-card-3--description-container .description {
			padding: 30px;
		}

		/*.theme-card-3:hover .theme-card-3--description-container {
			height: 100%;
			transition: 1s;
		}*/

		.theme-card-3 .theme-card-3--description-container>p {
			opacity: 0;
			display: none;
		}

		.theme-card-3:hover .theme-card-3--description-container>p {
			opacity: 1;
			transition: 1s ease-in;
			display: block;
		}
	}

	@media (max-width: 766px) {

		.theme-card-3--description-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			padding: 30px;

		}
	}
}